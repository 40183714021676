import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import Layout from "../components/layout"
import Container from "../components/container"

const LegalTemplate = ({ data }) => {
  const { content } = data

  return (
    <Layout hasBackgroundGray>
      <HelmetDatoCms seo={content.seoMetaTags} />
      <Container>
        <article className="pt-5 pb-20 md:pt-10 md:pb-40">
          <header>
            <h1 className="text-3xl font-black md:text-4xl">{content.title}</h1>
          </header>
          <div
            dangerouslySetInnerHTML={{ __html: content.body }}
            className="mt-10 rich-text md:mt-20"
          />
        </article>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query LegalTemplateQuery($id: String!) {
    content: datoCmsPageLegal(id: { eq: $id }) {
      title
      body
      seoMetaTags {
        tags
      }
    }
  }
`

export default LegalTemplate
